<template>
	<div>
		<SingleTask />
	</div>
</template>	

<script>
import SingleTask from '@/components/templates/SingleTask'
export default {
	components: {
		SingleTask
	}
}
</script>