<template>
    <div class="custom-input-container">
        <div
            v-for="(item, index) in items"
            @click="update(item)"
            class="item"
            :class="{ highlight: selectedItem === item }"
            :key="index"
        >
            {{ item }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // value prop is required
        value: {
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedItem: "",
        };
    },
    methods: {
        update(value) {
            this.selectedItem = value;
            // emit an input event
            this.$emit("input", value);
        },
    },
};
</script>

<style scoped lang="scss">
.custom-input-container {
    font-size: 0px;
    .item {
        display: inline-block;
        padding: 5px;
        border: 1px solid #eee;
        font-size: 16px;
        cursor: pointer;
        &:not(:last-child) {
            border-right: none;
        }
        &.highlight {
            background-color: powderblue;
        }
    }
}
</style>
