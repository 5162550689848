<template>
    <v-card v-if="logs" class='pa-2'>
        <div 
		class='my-4'
		v-for="(log, index) in logs" 
		:key='index'>
            <v-list-item two-line >
                <v-list-item-content>
                    <v-list-item-subtitle class="mb-1 text-left">
                        {{log.date.split('T')[0]}} {{correctDate(log.date)}}
                    </v-list-item-subtitle>
                    <v-list-item-title class="mb-1 text-left">
                        Użytkownik  
						<span class="indigo--text">{{log.user}}</span> 
						zmienił status zadania z 
						<StatusChip :status="log.fromStatus" /> 
						na 
						<StatusChip :status="log.toStatus" />
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </div>
    </v-card>
</template>

<script>
import io from 'socket.io-client';
import StatusChip from '@/components/molecules/StatusChip';

export default {
	name: "Logs",
	components: {
		StatusChip
	},
    data(){
        return {
            socket: io(this.url),
            logs: null
        }
    },
    props: {
        id: String,
    },
    methods: {
		correctDate(date){
			const newDate = new Date(date);
			return `${newDate.getHours()}:${newDate.getMinutes() < 9 ?  '0' + newDate.getMinutes() : newDate.getMinutes()}`
		},
        getLogs(){
            this.$axios.get(`${this.url}api/logs/${this.id}`)
                .then(resp => this.logs = resp.data)
        },
    },
    created() {
        this.getLogs();
        this.socket.on('changedStatus', () => {
            this.getLogs()
        });
    },
    
}
</script>